import React from "react"
import Layout from "../components/layout"
import RecommendationTemplateComponent from "../components/templates/recommendation";
import {graphql} from "gatsby";
import {isTest} from "../utilities";

const embedded = false;
const RecommendationTemplate = function ({ data }) {
  const recommendation = data.strapiRecommendation;
  const appStoreImage = data.appStore;
  const playStoreImage = data.playStore;
  const appScreenShotImage = data.appScreenShot;
  return (
    <Layout embedded={embedded}>
      <RecommendationTemplateComponent embedded={embedded} recommendation={recommendation} isTest={isTest()} storeImages={{appStoreImage, playStoreImage, appScreenShotImage}} />
    </Layout>
)}

export default RecommendationTemplate

export const query = graphql`
  query RecommendationTemplate($Item: String!) {
    strapiRecommendation(Item: { eq: $Item }) {
      Item
      Body
      Heading1
      Content1
      Heading2
      Content2
      Heading3
      Content3
      Products {
        id
        Title
        Url
        Image_url
        Amazon_tracking_img
        Public
      }
      Resources {
        id
        Title
        Url
        Public
      }
      checklists {
        Title
      }
    }
    playStore: file(relativePath: { eq: "google-play.png" }) {
      childImageSharp {
        fixed(width: 192, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    appStore: file(relativePath: { eq: "app-store.png" }) {
      childImageSharp {
        fixed(width: 192, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    appScreenShot: file(relativePath: { eq: "cc-app-track-items.png" }) {
      childImageSharp {
        fixed(width: 192, height: 382) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`